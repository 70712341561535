import React from 'react';
import config from '../../config';

import Layout from '../components/Layout';
import pic1 from '../assets/images/markaz/4.png';

const IndexPage = () => (
  <Layout fullMenu NoFooter>
    <section id="wrapper">
      <header>
        <div className="inner"></div>
      </header>
      <div className="wrapper">
        <div className="inner">
          <p>
            <article>
              <span className="image left">
                <img src={pic1} alt="" />
                <span> Shaykh Abdirahman As-Susi </span>
              </span>
            </article>
            Founded in 2017, AlItqaan Center strives to educate the Minnesota
            (and beyond!) community with our structured and traditional Islamic
            curriculum. We take pride in teaching people of all ages and genders
            how to read and write the Arabic language, properly recite the
            Quran, and more. Our goal is to give every student with everything
            they need so they can worship with certainty and ease. We hope you
            can join us so we can help you on your journey too.
          </p>

          <section className="features">
            <article style={{ padding: '0.8em 1.0em 0.5em 0.8em' }}>
              <ul className="contact">
                <li style={{ paddingBottom: '2.5em' }} className="fa-home">
                  {config.address}
                </li>
                <iframe
                  title="alitqan"
                  src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=12119%2016th%20Ave%20S%20Burnsville+(Al%20Itqan%20Center)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  frameborder="0"
                  style={{ height: '100%', width: '100%', border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </ul>
            </article>
            <article>
              <ul className="contact">
                <li className="fa-phone">{config.phone}</li>
                <li
                  className="fa-youtube"
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/channel/UCbkunDBN5mwCxz3U1SsFwYQ'
                    )
                  }
                >
                  <p
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/channel/UCbkunDBN5mwCxz3U1SsFwYQ'
                      )
                    }
                  >
                    Visit our YouTube channel!
                  </p>
                </li>
                <li>
                  <a href="/Donate" className="button primary">
                    Donate
                  </a>
                </li>
              </ul>
            </article>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
